// import tour from "../../templates/projects/_tour.js";
// import Vue from "vue"
import { nextTick } from "vue";

export default class {
  execute(properties, component) {
    // tour.stopPreviousTour(component)

    const snapshot = properties.snapshot
    const historySummaries = properties.historySummaries

    // For added safety to allow time for components to get registered.
    nextTick(() => {
      const hillChart = GLib.component.findById('hill_chart')
      hillChart.displaySnapshot(snapshot)

      this.populateBreadcrumbs(snapshot, historySummaries, properties.project)
    })
  }

  async populateBreadcrumbs(snapshot, snapshots, project) {
    const t = TrelloPowerUp.iframe()

    await $tpu.urls.preloadBoard(t)

    const projectNameCrumb = GLib.component.findById('crumb_project_name')
    projectNameCrumb.action_merge({
      text: project.name.truncate(30),
      onClick: {
        "action": "windows/open",
        "url": $tpu.urls.project(project.id)
      }
    })

    const snapshotNameCrumb = GLib.component.findById('crumb_snapshot_name')
    if (snapshot) {
      snapshotNameCrumb.action_merge({
        text: snapshot.description.truncate(30)
      })
    } else {
      snapshotNameCrumb.action_merge({
        text: "No snapshot"
      })
    }

    const actionCrumb = GLib.component.findById('crumb_action')
    actionCrumb.action_merge({
      onClick: {
        action: "popovers/open",
        styleClasses: ['popover-menu'],
        placement: "bottom-start",
        width: 300,
        childViews: snapshots.map((snapshot, index) => {
          return {
            "view": "label",
            "styleClasses": ["popover-menu-item"],
            "text": `#${index + 1} ${snapshot.description}`,
            "onClick": {
              "action": "windows/open",
              "url": $tpu.urls.snapshot(project.id, snapshot.id)
            }
          }
        }).reverse()
      }
    })
  }
}
